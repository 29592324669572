import { actionCreator } from "@/utils/helpers";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_COMMENTS_VALUE = "SET_COMMENTS_VALUE";
export const SET_REMINDERS_VALUE = "SET_REMINDERS_VALUE";
export const SET_ACTIVITY_VALUE = "SET_ACTIVITY_VALUE";
export const SET_NEXT_STEP = "SET_NEXT_STEP";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export const generalSettingsInitialState = {
  companyLogo: "",
  companyName: "",
  companyWebsite: "",
  companyAddress: "",
  companyPostalAddress: "",
  companyGeneralInformationEmail: "",
  phoneNumbers: [],
  multiLanguageSupport: true,
  generalSettingsEditStatus: false,
  companyId: "",
  organization: ""
};

const financeInformationInitialState = {
  taxRegistrationNumber: "",
  systemCurrency: {
    label: "UAE Dirham (AED)",
    value: "AED",
  },
  localCurrency: {
    label: "UAE Dirham (AED)",
    value: "AED",
  },
  chartAccountSystem: true,
  fiscalCalendarYear: "",
  numberofPeriods: "",
  fiscalYearTenure: "",
  creditLimit: false,
  commitmentLimit: false,
  reservation: false,
  arInvoice: false,
  startFiscalCalendarYear: "",
  endFiscalCalendarYear: ""
};

const alerts = {
  newsAndUpdates: true,
  tipsAndTutorials: true,
  userResearch: true,

  commentsNotNotify: false,
  commentsMentionsOnly: false,
  allComments: true,

  remindersNotNotify: false,
  remindersImportantOnly: false,
  allreminders: true,

  activityNotNotify: false,
  allActivity: true,

};

export const initialState = {
  activeStep: 1,
  completedStep: 0,
  ...generalSettingsInitialState,
  ...financeInformationInitialState,
  ...alerts,
  uploadDocument: null,
  uploadDocumentList: []
};

export const companySettingsReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return ({
        ...state,
        [action.payload.key]: action.payload.value
      });

    case SET_COMMENTS_VALUE:
      return ({
        ...state,
        commentsNotNotify: action.payload.value[0],
        commentsMentionsOnly: action.payload.value[1],
        allComments: action.payload.value[2],
      });

    case SET_REMINDERS_VALUE:
      return ({
        ...state,
        remindersNotNotify: action.payload.value[0],
        remindersImportantOnly: action.payload.value[1],
        allreminders: action.payload.value[2],
      });

    case SET_ACTIVITY_VALUE:
      return ({
        ...state,
        activityNotNotify: action.payload.value[0],
        allActivity: action.payload.value[1]
      });

    case SET_NEXT_STEP:
      return ({
        ...state,
        activeStep: action.payload.value,
        // eslint-disable-next-line max-len
        completedStep: (state.completedStep <= 3 && (state.completedStep + 2 === action.payload.value)) ? state.completedStep + 1 : state.completedStep
      });

    case SET_INITIAL_STATE:
      return {
        ...action.payload
      };

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setCommentsValue = (value) => actionCreator(SET_COMMENTS_VALUE, { value });

export const setRemindersValue = (value) => actionCreator(SET_REMINDERS_VALUE, { value });

export const setActivityValue = (value) => actionCreator(SET_ACTIVITY_VALUE, { value });

export const setNextStep = (value) => actionCreator(SET_NEXT_STEP, { value });

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);

import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import useTaskRedirect from "@/hooks/useTaskRedirect";

const formatRequestForQuotation = (materialRequest) => {
  const data = {
    item: materialRequest?.itemDetail?.map((item) => ({
      item: {
        label: item.item.itemName,
        value: item.item.id,
      },
      quantity: item.quantity,
    })),
    itemType: {
      label: materialRequest.itemType,
      value: materialRequest.itemType
    },
    category: materialRequest?.category?.map((item) => ({
      label: item.name,
      value: item.id,
    }
    )),
    supplier: { label: materialRequest?.supplier.name, value: materialRequest?.supplier.id }
  };

  return data;
};

function RequestForQuotationForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const { redirect } = useTaskRedirect();

  const { data: materialRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("MaterialRequestObjectName")), state?.materialRequest?.value],
    () =>
      getDynamicObjectRecordById(dynamicObjectMap.get("MaterialRequestObjectName"), state?.materialRequest?.value),
    {
      enabled: Boolean(state?.materialRequest?.value),
    }
  );

  useEffect(() => {
    if (materialRequestData) {
      const formattedMaterialRequestQuotations = formatRequestForQuotation(materialRequestData);
      ref.current.setFormState(formattedMaterialRequestQuotations);
    }
  }, [materialRequestData, state?.materialRequest]);

  const onStateChange = (key, value) => {
    console.log("onStateChange", key, value);

    if (key === "materialRequest") {
      setState({
        ...state,
        [key]: value,
      });
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        layout="Request For Quotation Form"
        objectName={dynamicObjectMap.get("RequestForQuotationObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
      />
    </BoxedContent>
  );
}

export default RequestForQuotationForm;

import qs from "qs";
import request from "@/utils/api/helpers";

const baseUrl = "/workflow-task";
const workflowBaseUrl = "/workflows";

export const getTasksCount = (params = {}) => {
  const queryParams = qs.stringify(params);

  return request({
    url: `${baseUrl}/count?${queryParams}`
  });
};

export const getTasks = (data) => request({
  url: `${baseUrl}/search`,
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  data
});

export const getTaskById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const updateTask = (data) => request({
  url: `${baseUrl}`,
  method: "POST",
  headers: {
    "Content-type": "application/json",
  },
  data
});

export const createTask = (data) => request({
  url: `${baseUrl}/execute`,
  method: "POST",
  headers: {
    "Content-type": "application/json",
  },
  data
});

export const cancelWorkflow = (data) => request({
  url: `${baseUrl}/cancel`,
  method: "POST",
  headers: {
    "Content-type": "application/json",
  },
  data
});

export const getAllWorkflowList = () => request({
  url: "/workflows"
});

export const getWorkflowSchema = (workflowName) => request({
  url: `/workflows/${workflowName}`
});

export const getWorkflowSteps = (params) => {
  const queryParams = qs.stringify(params);

  return request({
    url: `/workflows/${params.workflowId}/steps?${queryParams}`
  });
};

export const startWorkflow = (recordId) => request({
  url: `${workflowBaseUrl}/start?recordId=${recordId}`,
  method: "POST",
  headers: {
    "Content-type": "application/json",
  }
});

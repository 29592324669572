import { getTenantOutstandingArchiveColumns, getTenantOutstandingArchiveRows } from "./tenant-outstanding-archive";
import { getTenantOutstandingArchive } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportTenantOutstandingArchive({ activeCompany }) {
  return (
    <DynamicReport
      reportName="rent-roll"
      activeCompany={activeCompany}
      columnsDataProvider={getTenantOutstandingArchiveColumns}
      rowsDataProvider={getTenantOutstandingArchiveRows}
      fetchData={getTenantOutstandingArchive}
      exportUrl="reports/export-query-report/tenant-outstanding-archive"
      filtersInitialState={{}}
      orderBy="TenantNumber"
      showDateRangeFilter={false}
      showDateFilter
    />
  );
}

import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal, useUser } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getPettyCashTransactionColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { formatDate, formatDecimalValues } from "@/utils/helpers";
import { getTaxRules } from "@/api/finance/taxRuleApi";

const formatTransaction = (transaction) => {
  const data = {
    key: transaction.id,
    technicianName: transaction.technician.name,
    date: new Date(transaction.date),
    asset: null,
    transactionDate: formatDate(new Date(transaction.date)),
    status: {
      label: "Pending Claim",
      value: "PendingClaim",
    },
    receiptNo: transaction.receiptNo,
    amount: transaction.amount,
    description: transaction.remarks,
    pettyCashTransactionId: transaction.id,
    createdAt: transaction.createdAt,
    isSelected: false,
    requestType: transaction.requestType
  };

  if (!isEmpty(transaction.building)) {
    console.log("This is building:", transaction.building, transaction.building.unitProperty);
    data.asset = {
      ...transaction.building,
      label: transaction.building.name,
      value: transaction.building.id,
      unitProperty: transaction.building.unitProperty,
      lookupObjectName: "Building"
    };
  }

  if (!isEmpty(transaction.component)) {
    data.asset = {
      label: transaction.component.name,
      value: transaction.component.id,
      lookupObjectName: "Component"
    };
  }

  if (!isEmpty(transaction.expenseType)) {
    data.expenseType = {
      label: transaction.expenseType.name,
      value: transaction.expenseType.id,
    };
  }

  if (transaction.requestType === "WorkOrderJob") {
    if (transaction.workOrderJob) {
      const { building, unit } = transaction.workOrderJob;

      if (building) {
        console.log("This is workorder building:", building);
        data.asset = {
          ...transaction.building,
          ...building,
          label: building.name,
          value: building.id,
          lookupObjectName: "Building"
        };
      }

      if (unit) {
        data.unit = {
          ...unit,
          label: unit.name,
          value: unit.id
        };

        const { component } = unit;
        if (!isEmpty(component)) {
          data.asset = {
            label: component.name,
            value: component.id,
            lookupObjectName: "Component"
          };
        }
      }
    }
  }

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select petty cash transactions
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function PettyCashClaimForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const { user } = useUser();

  const { data: transactionsData } = useQuery(
    [kebabCase(dynamicObjectMap.get("PettyCashTransactionObjectName")), state?.employee?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("PettyCashTransactionObjectName"), {
        technician: state?.employee?.value,
        type: "Credit",
        status: "PendingClaim",
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.employee?.value),
    }
  );

  const { data: taxGroupData } = useQuery(["tax-rule"], getTaxRules);

  useEffect(() => {
    const userRole = user?.roles;
    if (userRole && (userRole.includes("supervisor") || userRole.includes("cash_officer"))) {
      const supervisor = {
        label: user?.name,
        value: user?.userKey
      };

      setState((prevState) => ({
        ...prevState,
        employee: supervisor,
        isSupervisor: true
      }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (transactionsData && transactionsData?.data) {
        const formattedTransactions = transactionsData?.data.map((t) => formatTransaction(t));
        if (formattedTransactions.length) {
          setTransactionsTableData(formattedTransactions);
        } else {
          setTransactionsTableData([]);
        }
        openModal();
      }
    })();
  }, [transactionsData]);

  const setTotal = (value) => {
    const approvedTransactions = value.filter((t) => t?.status?.value !== "Rejected");

    const totalAmount = approvedTransactions.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );
    const approvedAmount = approvedTransactions.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const transaction = value?.map((item) => ({
      ...item,
      amountBeforeTax: formatDecimalValues(item.amountBeforeTax),
      amount: formatDecimalValues(item.amount),
      taxAmount: formatDecimalValues(item.taxAmount),
    }));

    ref.current.setFormState({
      total: totalAmount,
      transaction,
      totalApproved: approvedAmount,
    });
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "employee":
        setState({
          ...state,
          [key]: value,
        });
        break;

      case "transaction":
        setTotal(value);
        break;

      default:
        break;
    }
  };

  const getTaxByBuildingType = (buildingType) => {
    let tax = null;
    console.log({ taxGroupData });
    switch (buildingType) {
      case "Residential":
        tax = taxGroupData?.data.find((i) => i.name === "Input VAT Non-recoverable - Residential (5%)");
        break;

      case "Commercial":
        tax = taxGroupData?.data.find((i) => i.name === "Input VAT Recoverable - Commercial (5%)");
        break;

      case "ResidentialAndCommercial":
        tax = taxGroupData?.data.find((i) => i.name === "Input VAT Partial-recoverable Admin (5%)");
        break;

      default:
        break;
    }

    if (tax) {
      tax.label = tax.name;
      tax.value = tax.id;
    }

    return tax;
  };

  const handleConfirm = () => {
    if (transactionsTableData.length) {
      let selectedTransactions = transactionsTableData.filter((r) => r.isSelected);
      const totalAmount = selectedTransactions.reduce(
        (total, currentValue) => total + Number(currentValue.amount),
        0
      );

      selectedTransactions = selectedTransactions.map((item) => {
        const taxGroup = getTaxByBuildingType(
          item?.unit?.unitProperty ?? item?.asset?.unitProperty
        );
        console.log("taxGroup:", item, taxGroup, item?.unit?.unitProperty ?? item?.asset?.unitProperty);
        const amountBeforeTax = (Number(item.amount) * 100) /
          (100 + Number(taxGroup?.purchaseRate || 0));
        const taxAmount = Number(item.amount) - Number(amountBeforeTax);

        return {
          ...item,
          tax: taxGroup,
          amountBeforeTax,
          taxAmount
        };
      });

      const sortedTransaction = selectedTransactions.sort((a, b) =>
        (b.updateSequence) - (a.updateSequence));

      ref.current.setFormState({
        transaction: sortedTransaction,
        total: totalAmount,
      });
    }

    closeModal();
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "transaction") {
      switch (key) {
        case "expenseType": {
          parentFieldState[key] = value;

          if (!value) {
            parentFieldState.account = null;
          }
          break;
        }

        case "amountBeforeTax":
        case "tax": {
          parentFieldState[key] = value;
          const { tax, amountBeforeTax } = parentFieldState;
          if (tax && amountBeforeTax) {
            const { purchaseRate } = tax;
            const taxAmount = (Number(amountBeforeTax) * Number(purchaseRate)) / 100;
            const amount = Number(amountBeforeTax) + Number(taxAmount);
            parentFieldState.taxAmount = formatDecimalValues(taxAmount);
            parentFieldState.amount = formatDecimalValues(amount);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Petty Cash Transactions"
        subtitle="Selected employee has following open petty cash transactions"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {transactionsTableData.length ? (
          <TableWithCheckbox
            searchKey={["receiptNo"]}
            data={transactionsTableData}
            setData={setTransactionsTableData}
            columns={getPettyCashTransactionColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        initialData={state}
        ref={ref}
        objectName={dynamicObjectMap.get("PettyCashClaimObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        readOnlyFields={state.isSupervisor ? ["employee"] : []}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(transactionsTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default PettyCashClaimForm;

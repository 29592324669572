import { useState } from "react";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview, { defaultComponents } from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { SelectWorkOrderTypeModal } from "@/components/modals";
import { useUser } from "@/hooks";

function HeaderRightContent(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <defaultComponents.HeaderRightContent {...props} onClickNew={() => setIsOpen(true)}>
      <SelectWorkOrderTypeModal isOpen={isOpen} onClose={setIsOpen} />
    </defaultComponents.HeaderRightContent>
  );
}

function RequestForQuotationOverview() {
  const { user, userType } = useUser();
  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("RequestForQuotationObjectName")}
      components={{
        HeaderRightContent
      }}
      showFilters
      filters={userType === "Supplier" ? {
        supplier: user?.supplier?.id,
      } : null}
    />
  );
}

export default RequestForQuotationOverview;

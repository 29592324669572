import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  Form, FormControl, Label, Button, Radio, TextArea, Input
} from "@hydra/atom/components";
import { isSameDay } from "date-fns";
import { kebabCase } from "lodash";
import { useIsMobile, useModal } from "@/hooks";
import { SelectField, LookupField } from "@/components/dynamic/fields";
import { renderField } from "@/utils/dynamic/helpers";

import { AlertModal } from "@/components/modals";
import { setFormValue } from "@/reducers/facility/workOrderFormReducer";
import {
  CustomizedDateRangePicker,
  CustomizedTimeRangePicker,
  Header,
  BoxedContent,
  RequirePermission,
  Accordion,
  HeaderLeftContent
} from "@/components/common";
import { AddWorkOrderInspectionList } from "@/components/facility/planboard";
import { getFullName, getTestId } from "@/utils/helpers";
import { checkStartAndEndTimeForSameDate, checkIfArrayValueIsNull } from "@/utils/facility/helpers";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { formatTechnician, checkOvertime } from "./helpers";

const formatEmployee = (employee, state) => {
  const technician = formatTechnician(employee);
  const job = state?.jobs.find((jobItem) => jobItem.id === state.selectedJobId);
  const technicianExists = job?.technician.some((member) => member.value === technician.value);

  return {
    ...employee,
    label: technician.label,
    subtitle: technician.designation?.name,
    value: technician.value,
    selected: technicianExists,
    date: technician.date,
    time: technician.time,
    startingHour: technician.startingHour,
    endingHour: technician.endingHour,
  };
};

const formatSupervisor = (supervisor, state) => {
  const job = state?.jobs.find((jobItem) => jobItem.id === state.selectedJobId);
  const supervisorExists = job?.externalSupervisor?.value === supervisor?.id;
  return {
    ...supervisor,
    label: getFullName(supervisor),
    subtitle: supervisor?.supplier?.name,
    value: supervisor?.id,
    selected: supervisorExists,
    date: "Jan 19 - Jan 20",
    time: "11:00 AM - 1:00 PM",
    supplier: {
      label: supervisor?.supplier?.name,
      value: supervisor?.supplier?.id,
    },
  };
};

const getSupplierStaffRecords = async (params) => {
  const response = await getDynamicObjectRecords(
    dynamicObjectMap.get("SupplierStaffObjectName"),
    params
  );
  return response;
};

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

function AddWorkOrderInspectionStep({
  state,
  dispatch,
  onSubmit,
  isEditing,
  handlePrevious,
  loading,
}) {

  const { isOpen, closeModal, openModal } = useModal(false);
  const [step, setStep] = useState(1);
  const isMobileView = useIsMobile(992);
  const isMobileView1200 = useIsMobile(1200);

  const { data, isInitialLoading: isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("TechnicianSkillObjectName")),
      {
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        category: state?.category?.value,
        queryMode: "Deep"
      },
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("TechnicianSkillObjectName"), {
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        category: state?.category?.value,
        queryMode: "Deep"
      })
  );

  const { data: serviceRequestNotes } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("ServiceRequestNotesObjectName")),
      state?.serviceRequest?.value,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ServiceRequestNotesObjectName"), {
        detailId: state?.serviceRequest?.value,
      }),
    {
      enabled: Boolean(state?.serviceRequest?.value),
    }
  );

  const { data: externalSupervisorData, isInitialLoading: isExternalSupervisorLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("SupplierStaffObjectName")),
      {
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        designation: "Supervisor",
      },
    ],
    () =>
      getSupplierStaffRecords({
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        designation: "Supervisor",
      })
  );

  const { data: externalTechnicianData, isInitialLoading: isExternalTechnicianLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("SupplierStaffObjectName")),
      {
        takePage: 1,
        limitPage: 1,
        sortBy: "CreatedAt",
        sortType: "DESC",
        designation: "Technician",
        supplier: state?.externalSupervisor?.supplier?.value,
      },
    ],
    () =>
      getSupplierStaffRecords({
        takePage: 1,
        limitPage: 1,
        sortBy: "CreatedAt",
        sortType: "DESC",
        designation: "Technician",
        supplier: state?.externalSupervisor?.supplier?.value,
      }),
    {
      enabled: Boolean(state?.externalSupervisor?.supplier?.value),
    }
  );

  useEffect(() => {
    if (externalTechnicianData) {
      const externalTechnician = externalTechnicianData?.data[0];

      dispatch(
        setFormValue("externalTeam", {
          label: getFullName(externalTechnician),
          value: externalTechnician?.id,
        })
      );
    }
  }, [externalTechnicianData]);

  const list = useMemo(() => {
    if (!state.isVendor) {
      return data?.data ?
        data?.data
          .filter((item) => item.technician.status === "Active")
          .map((e) => formatEmployee(e, state)) :
        [];
    }
    return externalSupervisorData?.data ?
      externalSupervisorData?.data.map((e) => formatSupervisor(e, state)) :
      [];
  }, [data, state.isVendor, externalSupervisorData]);

  const { data: objectSchema } = useQuery(
    ["dynamic-object-schema-camelized-name", kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("WorkOrderJobObjectName"))
  );

  const statusField = useMemo(
    () => objectSchema?.document.find((f) => f.name === "jobStatus") || [],
    [objectSchema]
  );

  const materialField = useMemo(
    () => objectSchema?.document.find((f) => f.camelizedName === "materials"),
    [objectSchema]
  );

  const jobForField = useMemo(
    () => objectSchema?.document.find((f) => f.camelizedName === "jobFor"),
    [objectSchema]
  );

  useEffect(() => {
    if (objectSchema) {
      if (!state.isEditingJob) {
        const { status } = state;

        const defaultStatus = statusField?.options.find((o) => o.default);
        if (!status) {
          dispatch(setFormValue("status", defaultStatus));
        }
      }
    }
  }, [objectSchema]);

  const handleSubmit = () => {
    if (!state.isVendor &&
      (checkIfArrayValueIsNull(state.dateRange) ||
       checkIfArrayValueIsNull(state.timeRange) ||
       state.technician.length === 0)) {
      return;
    }

    if (state.isVendor && !state.externalSupervisor) return;
    if (
      isSameDay(state.dateRange[0], state.dateRange[1]) &&
      checkStartAndEndTimeForSameDate(state.timeRange) &&
      !state.isVendor
    ) {
      return;
    }

    if (isMobileView && step === 1) {
      setStep(2);
      return;
    }

    if (checkOvertime(state)) {
      openModal();
      return;
    }

    onSubmit();
  };

  const isDisabled = useMemo(() => {
    if (!state.isVendor &&
      (checkIfArrayValueIsNull(state.dateRange) ||
       checkIfArrayValueIsNull(state.timeRange) ||
       state.technician.length === 0)) {
      return true;
    }

    if (state.isVendor && !state.externalSupervisor) return true;
    if (
      isSameDay(state.dateRange[0], state.dateRange[1]) &&
      checkStartAndEndTimeForSameDate(state.timeRange) &&
      !state.isVendor
    ) {
      return true;
    }

    return false;
  }, [state]);

  const onRadioChange = (value) => {
    if (!value) {
      dispatch(setFormValue("externalTeam", null));
    }
    dispatch(setFormValue("isVendor", value));
  };

  useEffect(() => {
    if (!state.isVendor && list.length > 0) {
      dispatch(setFormValue("externalSupervisor", null));
      dispatch(setFormValue("internal", true));
    } else {
      dispatch(setFormValue("technician", []));
      dispatch(setFormValue("internal", false));
    }
  }, [list]);

  const checkDateTimeFieldRender = () => {
    if (state.isVendor) {
      if (!state.proposedTime.length) {
        return false;
      }
    }

    return true;
  };

  const checkDateTimeDisabled = () => {
    if (state.isVendor) {
      if (state.proposedTime.length) {
        return true;
      }
    }
    return false;
  };
  const getInspectionListValue = () => {
    if (state.internal === false) {
      if (state.externalSupervisor) return [state.externalSupervisor];
      return undefined;
    }
    return state.technician;
  };
  return (
    <>
      {!isMobileView && (
        <BoxedContent className="primary-right-border">
          <Header
            showBreadcrumb
            leftContent={<HeaderLeftContent title="Schedule" subtitle={state.activeStep === 4 ? "Schedule Your Inspection" : "Schedule Your Workorder Job"} />}
          />
          <div className="core-inspection-modal-content">
            <Form onSubmit={handleSubmit} className="modal-form add-work-order-job-step">
              <div className="row">
                {!(state.isVendor && state.isEditingJob) && (
                  <div className="col-6 schedule-radio">
                    <Radio
                      label="Internal Technicians"
                      itemValue
                      type="radio"
                      value={!state.isVendor}
                      onChange={() => onRadioChange(false)}
                      testId={getTestId("Inspection-IsVendor-Radio-0")}
                    >
                      <Radio.CheckIcon>
                        <span className="material-icons">circle</span>
                      </Radio.CheckIcon>
                    </Radio>
                  </div>
                )}
                {!(!state.isVendor && state.isEditingJob) && (
                  <div className="col-md-6 schedule-radio">
                    <Radio
                      label="External Vendor"
                      itemValue
                      type="radio"
                      value={state.isVendor}
                      onChange={() => onRadioChange(true)}
                      testId={getTestId("Inspection-IsVendor-Radio-1")}
                    >
                      <Radio.CheckIcon>
                        <span className="material-icons">circle</span>
                      </Radio.CheckIcon>
                    </Radio>
                  </div>
                )}
                {state.activeStep === 3 && (
                <div className={isMobileView1200 ? "col-12" : "col-9"}>
                  <FormControl>
                    <Label htmlFor="jobTitle" label="Title" required />
                    <Input
                      autoFocus
                      type="text"
                      name="jobTitle"
                      id="jobTitle"
                      placeholder="Title"
                      value={state.jobTitle}
                      onChange={(value) => dispatch(setFormValue("jobTitle", value))}
                      rules="required"
                      messages={validationMessages.required}
                      disabled={state.isReadOnly}
                    />
                  </FormControl>
                  <FormControl>
                    <Label htmlFor="jobDescription" label="Description" />
                    <TextArea
                      type="text"
                      name="jobDescription"
                      id="jobDescription"
                      placeholder="Description"
                      value={state.jobDescription}
                      onChange={(value) => dispatch(setFormValue("jobDescription", value))}
                      disabled={state.isReadOnly}
                      rules="required"
                      messages={validationMessages.required}
                    />
                  </FormControl>
                </div>
                ) }
                {checkDateTimeFieldRender() && (
                  <div className={isMobileView1200 ? "col-12" : "col-9"}>
                    <FormControl>
                      <Label label="Date*" labelFor="dateRange" />
                      <CustomizedDateRangePicker
                        name="dateRange"
                        value={state.dateRange}
                        onChange={(value) => dispatch(setFormValue("dateRange", value))}
                        required
                        disabled={state.isReadOnly || checkDateTimeDisabled()}
                        testId={getTestId("Inspection-DateRange")}
                      />
                    </FormControl>
                  </div>
                )}
                {checkDateTimeFieldRender() && (
                  <div className={isMobileView1200 ? "col-12" : "col-9"}>
                    <FormControl>
                      <Label htmlFor="timeRange" label="Time*" />
                      <CustomizedTimeRangePicker
                        name="timeRange"
                        value={state.timeRange}
                        onChange={(value) => dispatch(setFormValue("timeRange", value))}
                        required
                        disabled={state.isReadOnly || checkDateTimeDisabled()}
                        testId={getTestId("Inspection-TimeRange")}
                      />
                    </FormControl>
                  </div>
                )}
                <div className={isMobileView1200 ? "col-12" : "col-10"}>
                  <FormControl>
                    <SelectField
                      field={{ ...statusField, label: state.activeStep === 4 ? "Status of inspection" : "Status of workorder job" }}
                      value={state.status}
                      onChange={(value) => dispatch(setFormValue("status", value))}
                      testId={getTestId("Inspection-Status-Select")}
                    />
                  </FormControl>
                </div>
                {state.type === "scheduled" && objectSchema && jobForField && (
                <div className={isMobileView1200 ? "col-12" : "col-10"}>
                  <FormControl>
                    {renderField({
                      name: "jobFor",
                      id: "jobFor",
                      field: jobForField,
                      isDisabled: state.isReadOnly,
                      state,
                      onChange: (key, value) => dispatch(setFormValue(key, value)),
                      parentId: state.selectedJobId
                    })}
                  </FormControl>
                </div>
                )}
                {!state.isVendor && state.jobType === "Job" && objectSchema && materialField && (
                <div className={isMobileView1200 ? "col-12" : "col-12"}>
                  <FormControl>
                    {renderField({
                      name: "materials",
                      id: "materials",
                      field: materialField,
                      isDisabled: state.isReadOnly,
                      state,
                      onChange: (key, value) => dispatch(setFormValue(key, value)),
                      parentId: state.selectedJobId
                    })}
                  </FormControl>
                  <FormControl>
                    <LookupField
                      field={objectSchema?.document.find((f) => f.camelizedName === "equipment")}
                      id="equipment"
                      name="equipment"
                      value={state.equipment}
                      onChange={(value) => dispatch(setFormValue("equipment", value))}
                      placeholder="Select equipment"
                      disabled={state.isReadOnly}
                    />
                  </FormControl>
                </div>
                )}
                {state.isVendor && (
                  <div className={isMobileView1200 ? "col-12" : "col-10"}>
                    <FormControl>
                      <Label htmlFor="externalNote" label="Note for external supplier" />
                      <TextArea
                        name="externalNote"
                        value={state.externalNote}
                        onChange={(value) => dispatch(setFormValue("externalNote", value))}
                        testId={getTestId("Inspection-ExternalNote-Textarea")}
                      />
                    </FormControl>
                  </div>
                )}
                {state?.notes?.length > 0 && isEditing && (
                  <Accordion
                    title="Technician's Inspection Comments"
                    className={`col-12 ${isMobileView ? "mb-5" : ""}`}
                  >
                    {state?.notes.map((item) => (
                      <div className="row">
                        <div className="col-12">
                          <FormControl>
                            <TextArea name="textArea" value={item} disabled />
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </Accordion>
                )}
                {serviceRequestNotes && serviceRequestNotes.data.length > 0 && isEditing && (
                  <Accordion
                    title="Tenant Proposed Time"
                    className={`col-12 ${isMobileView ? "mb-5" : ""}`}
                  >
                    {serviceRequestNotes.data.map((item) => (
                      <div className="row">
                        <div className="col-12">
                          <FormControl>
                            <TextArea name="textArea" value={item?.note} disabled />
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </Accordion>
                )}
              </div>
            </Form>
          </div>
          <div className="save-button-container">
            <RequirePermission
              parent="Model"
              scope={dynamicObjectMap.get("WorkOrderJobObjectName")}
              action={isEditing ? "Update" : "Insert"}
            >
              <Button
                className="btn-primary"
                bordered
                small
                loading={loading}
                onClick={handleSubmit}
                testId={getTestId("Inspection-Submit-Button")}
                disabled={isDisabled}
              >
                Save
              </Button>
            </RequirePermission>
            <Button
              bordered
              small
              onClick={handlePrevious}
              testId={getTestId("Inspection-Cancel-Button")}
            >
              Cancel
            </Button>
          </div>
        </BoxedContent>
      )}
      {!isMobileView && (
        <div className="inspection-list-container">
          <AddWorkOrderInspectionList
            dispatch={dispatch}
            state={state}
            internal={!state.isVendor}
            value={getInspectionListValue()}
            list={list}
            proposedTime={state?.proposedTime}
            isLoading={isLoading || isExternalSupervisorLoading}
            onChange={(technicianList) => {
              if (!state.isVendor) {
                dispatch(setFormValue("technician", technicianList));
                dispatch(setFormValue("externalSupervisor", null));
              } else {
                dispatch(setFormValue("externalSupervisor", technicianList[0]));
                dispatch(setFormValue("technician", []));
              }
            }}
          />
        </div>
      )}
      {isMobileView && step === 1 && (
        <BoxedContent>
          <Header
            showBreadcrumb
            leftContent={<HeaderLeftContent title="Schedule" subtitle="Schedule Your Inspection" />}
          />
          <div className="core-inspection-modal-content">
            <Form onSubmit={handleSubmit} className="modal-form add-work-order-job-step">
              <div className="row">
                <div className="col-md-12 schedule-radio">
                  <Radio
                    label="Internal Technicians"
                    itemValue
                    type="radio"
                    value={!state.isVendor}
                    onChange={() => onRadioChange(false)}
                    testId={getTestId("Inspection-IsVendor-Radio-0")}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                </div>
                <div className="col-md-12 schedule-radio">
                  <Radio
                    label="External Technicians"
                    itemValue
                    type="radio"
                    value={state.isVendor}
                    onChange={() => onRadioChange(true)}
                    testId={getTestId("Inspection-IsVendor-Radio-1")}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                </div>
                {checkDateTimeFieldRender() && (
                  <div className="col-md-12">
                    <FormControl>
                      <Label label="Date*" labelFor="dateRange" />
                      <CustomizedDateRangePicker
                        name="dateRange"
                        value={state.dateRange}
                        onChange={(value) => dispatch(setFormValue("dateRange", value))}
                        required
                        disabled={state.isReadOnly || checkDateTimeDisabled()}
                        testId={getTestId("Inspection-DateRange")}
                      />
                    </FormControl>
                  </div>
                )}

                {checkDateTimeFieldRender() && (
                  <div className="col-md-12">
                    <FormControl>
                      <Label htmlFor="timeRange" label="Time*" />
                      <CustomizedTimeRangePicker
                        name="timeRange"
                        value={state.timeRange}
                        onChange={(value) => dispatch(setFormValue("timeRange", value))}
                        required
                        disabled={state.isReadOnly || checkDateTimeDisabled()}
                        testId={getTestId("Inspection-TimeRange")}
                      />
                    </FormControl>
                  </div>
                )}
                <div className={isMobileView1200 ? "col-12" : "col-10"}>
                  <FormControl>
                    <SelectField
                      field={{ ...statusField, label: "Status of inspection" }}
                      value={state.status}
                      onChange={(value) => dispatch(setFormValue("status", value))}
                      testId={getTestId("Inspection-Status-Select")}
                    />
                  </FormControl>
                </div>
                {state?.notes?.length > 0 && isEditing && (
                  <Accordion title="Technician's Inspection Comments">
                    {state?.notes.map((item) => (
                      <div className="row">
                        <div className="col-12">
                          <FormControl>
                            <TextArea name="textArea" value={item} disabled />
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </Accordion>
                )}
                {serviceRequestNotes && serviceRequestNotes.data.length > 0 && isEditing && (
                  <Accordion title="Tenant Proposed Time" className="tenant-proposed-accordion">
                    {serviceRequestNotes.data.map((item) => (
                      <div className="row">
                        <div className={isMobileView1200 ? "col-12" : "col-10"}>
                          <FormControl>
                            <TextArea name="textArea" value={item?.note} disabled />
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </Accordion>
                )}
              </div>
            </Form>
          </div>
          <div className="save-button-container">
            <RequirePermission
              parent="Model"
              scope={dynamicObjectMap.get("WorkOrderJobObjectName")}
              action={isEditing ? "Update" : "Insert"}
            >
              <Button
                className="btn-primary"
                bordered
                small
                loading={loading}
                onClick={() => handleSubmit()}
                testId={getTestId("Inspection-Next-Button")}
              >
                Next
              </Button>
            </RequirePermission>
            <Button
              bordered
              small
              onClick={handlePrevious}
              testId={getTestId("Inspection-Back-Button")}
            >
              Back
            </Button>
          </div>
        </BoxedContent>
      )}
      {isMobileView && step === 2 && (
        <div className="inspection-list-container">
          <AddWorkOrderInspectionList
            dispatch={dispatch}
            state={state}
            internal={!state.isVendor}
            value={getInspectionListValue()}
            list={list}
            proposedTime={state?.proposedTime}
            isLoading={isLoading || isExternalTechnicianLoading}
            onChange={(technicianList) => {
              if (!state.isVendor) {
                dispatch(setFormValue("technician", technicianList));
                dispatch(setFormValue("externalSupervisor", null));
              } else {
                dispatch(setFormValue("externalSupervisor", technicianList[0]));
                dispatch(setFormValue("technician", []));
              }
            }}
          />
          <div className="save-button-container d-grid">
            <RequirePermission
              parent="Model"
              scope={dynamicObjectMap.get("WorkOrderJobObjectName")}
              action={isEditing ? "Update" : "Insert"}
            >
              <div className="d-grid">
                <Button
                  className="btn-primary"
                  bordered
                  small
                  loading={loading}
                  onClick={() => handleSubmit()}
                  testId={getTestId("Inspection-Submit-Button")}
                >
                  Save
                </Button>
              </div>
            </RequirePermission>
            <div className="d-grid">
              <Button
                bordered
                small
                onClick={() => setStep(1)}
                testId={getTestId("Inspection-Back-Button")}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      )}
      <AlertModal
        subtitle="Job outside regular hours. Schedule overtime for technician?"
        onClose={closeModal}
        isOpen={isOpen}
        isLoading={loading}
        onConfirm={onSubmit}
      />
    </>
  );
}

export default AddWorkOrderInspectionStep;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { kebabCase, startCase } from "lodash";
import { useSelector } from "react-redux";
import qs from "qs";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { camelize, pascalize } from "humps";
import showToast from "@/utils/toast/helpers";
import appSettings from "@/settings";
import { SvgIcon } from "@/components/common";
import { DynamicObjectActionModal, AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import { selectPermissions } from "@/store/userSlice";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  deleteDynamicObjectRecord,
  getDynamicObjectRecords,
} from "@/api/dynamic/dynamicObjectNameApi";
import { selectActiveApp, selectActiveCompany } from "@/store/appSlice";
import { checkDynamicObjectPermission } from "@/utils/dynamic/helpers";
import { getTestId } from "@/utils/helpers";
import { getRoles } from "@/api/user/authApi";

const overridePermissions = {
  [dynamicObjectMap.get("BuildingObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("FloorObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("LandlordObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("ComponentObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("TenantObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("LandObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("BankAccountObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("BankObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("AssetClassObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("AssetGroupObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("ChequeLocationObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("ClassObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("LocationObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("PaymentTermObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("PDCObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("EmployeeObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("SupplierObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("ServiceRequestObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("ReservationObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("PettyCashRequestObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("SupervisorPettyCashRequestObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("PettyCashClaimObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("PettyCashTransactionObjectName")]: {
    delete: false,
  },
  [dynamicObjectMap.get("MoveOutRequestObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("MoveInRequestObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("ClearChequeObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("BounceChequeObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("RevenueRecognitionObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("PaymentObjectName")]: {
    edit: false,
  },
  [dynamicObjectMap.get("RentalInvoiceObjectName")]: {
    edit: false,
  },
  // Role permission override
  tsa: {
    [dynamicObjectMap.get("ServiceRequestObjectName")]: {
      delete: false,
    },
  },
  extsupervisor: {
    [dynamicObjectMap.get("WorkOrderJobObjectName")]: {
      edit: false,
      view: false,
    },
  },
  admin: {
    [dynamicObjectMap.get("ContractObjectName")]: {
      edit: true,
    },
    [dynamicObjectMap.get("PaymentReceiptObjectName")]: {
      edit: true,
    },
  },
  [dynamicObjectMap.get("ContractObjectName")]: {
    edit: false,
    delete: false,
  },
  [dynamicObjectMap.get("PaymentReceiptObjectName")]: {
    edit: false,
  },
};

const checkPermissionOverride = (userRoles, objectName, permission) => {
  for (let i = 0; i < userRoles.length; i += 1) {
    const role = userRoles[i];
    if (Object.prototype.hasOwnProperty.call(overridePermissions, role)) {
      const rolePermission = overridePermissions[role];
      if (Object.prototype.hasOwnProperty.call(rolePermission, objectName)) {
        const objectPermission = rolePermission[objectName];
        if (Object.prototype.hasOwnProperty.call(objectPermission, permission)) {
          return objectPermission[permission];
        }
      }
    }
  }

  if (Object.prototype.hasOwnProperty.call(overridePermissions, objectName)) {
    const customPermission = overridePermissions[objectName];
    if (Object.prototype.hasOwnProperty.call(customPermission, permission)) {
      return customPermission[permission];
    }
  }

  return true;
};

function ActionDropdown({
  objectName: dynamicObjectName,
  id,
  data,
  actions,
  trigger,
  showTableActions,
  testId,
  removeActions,
}) {
  const [navigationUrl, setNavigationUrl] = useState("");
  const [activeAction, setActiveAction] = useState(null);
  const permissions = useSelector(selectPermissions);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const activeApp = useSelector(selectActiveApp);
  const activeCompany = useSelector(selectActiveCompany);
  const objectName = dynamicObjectName ? pascalize(dynamicObjectName) : dynamicObjectName;
  const [units, setUnits] = useState([]);
  const {
    isOpen: isActionModalOpen,
    closeModal: closeActionModal,
    openModal: openActionModal,
  } = useModal(false);

  const {
    isOpen: isAlertModalOpen,
    closeModal: closeAlertModal,
    openModal: openAlertModal,
  } = useModal(false);

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), units],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ContractObjectName")), {
        "unit[in]": units.map((v) => v).join(", "),
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        "status[in]": "Open,Active,Planned,Approved"
      }),
    {
      enabled: Boolean(units.length > 0),
    }
  );

  useEffect(() => {
    const filteredData = contractData?.data?.filter((item) => item.id !== data.key);
    if (filteredData?.length > 0) {
      showToast(
        `Cannot renew ${startCase(objectName)} because it already has a contract .`,
        "error"
      );
    } else if (navigationUrl) {
      navigate(
        navigationUrl
      );
    }

  }, [contractData?.data]);

  const handleRenewContract = (url) => {
    setUnits(data.unit?.map((item) => item.value));
    setNavigationUrl(url);
  };
  const handleDeleteAction = () => {
    if (
      objectName === dynamicObjectMap.get("UnitObjectName") &&
      ["Occupied", "Reserved"].includes(data.status)
    ) {
      showToast(
        `Cannot delete ${startCase(objectName)} because it has an active contract or reservation.`,
        "error"
      );
      return;
    }

    openAlertModal();
  };

  const deleteMutation = useMutation(({ key }) => deleteDynamicObjectRecord(objectName, key), {
    onError: () => {
      showToast(`Could not delete ${startCase(objectName)}. Try again!`, "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [kebabCase(objectName)],
      });
      showToast(`${startCase(objectName)} deleted successfully`, "success");
    },
  });

  if (!id) {
    return null;
  }

  const handleEdit = () => {
    if (objectName === dynamicObjectMap.get("WorkOrderJobObjectName")) {
      navigate(`/${kebabCase(activeApp?.value)}/work-order/${data.workOrder.id}?jobId=${id}`);
    } else {
      navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/${id}`);
    }
  };

  const handleView = () => {
    navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/details/${id}`);
  };

  const handlePrintBarCode = (action, recordData) => {
    try {
      const image = new Image();
      image.src = `data:image/png;base64,${recordData.barCode}`;
      const w = window.open("");
      w.document.write(image.outerHTML);
    } catch (error) {
      showToast(`Could not ${action.name}. Please try again`, "error");
    }
  };

  function getNestedValue(dataIem, path) {
    const parts = path.split(".");
    let value = dataIem;

    for (const part of parts) {
      const key = camelize(part);
      if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        return undefined;
      }
    }

    return value;
  }

  const handlePrint = async (action) => {
    const { apiAction, apiUrl } = action;

    const params = {};
    const parts = action.objectName.split(", ").map((part) => part.trim());

    parts.forEach((part) => {
      const subParts = part.split(".");
      if (subParts.length >= 2) {
        subParts.shift();
        const name = getNestedValue(data, subParts.join(".").toLowerCase());
        if (name) {
          params[subParts[0]] = name;
        }
      }
    });
    const queryParams = qs.stringify(params);
    const { baseUrl } = appSettings;
    const requestParams = {
      url: `${baseUrl}/${apiUrl}?${objectName}=${id}&CompanyId=${activeCompany.id}&${queryParams}`,
      method: apiAction,
      headers: {
        "Content-Type": "application/pdf",
      },
    };

    try {
      window.open(requestParams.url, "_blank");
    } catch (error) {
      showToast(`Could not ${action.name}. Please try again`, "error");
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate({ key: id });
    closeAlertModal();
  };

  const prepareActions = () => {
    const userRoles = getRoles();
    const accessibleActions = actions.filter((action) => {
      if (!action.roles) {
        return true;
      }

      if (action.roles.length === 0) {
        return true;
      }

      if (action.roles.some((role) => userRoles.includes(role))) {
        return true;
      }

      return false;
    });

    const permittedActions = accessibleActions?.filter((action) => {
      switch (action.actionType) {
        case "Create":
          return checkDynamicObjectPermission({
            permissions,
            scope: action.objectName,
            action: "Insert",
          });

        default: {
          /*
            Give access to TSA and MoveOut team roles for printing
            without giving access to editing a contract
          */
          if (objectName === dynamicObjectMap.get("ContractObjectName")) {
            const roles = ["tsa", "moveout_team"];
            const actionNames = ["Print Move-Out Checklist", "Print Move-In Checklist"];
            if (
              roles.some((role) => userRoles.includes(role)) &&
              actionNames.includes(action.name)
            ) {
              return true;
            }
          }

          return checkDynamicObjectPermission({
            permissions,
            scope: pascalize(objectName),
            action: "Update",
          });
        }
      }
    });

    let filteredActions = permittedActions.filter((action) => {
      // Activate / Deactivate actions for unit / building
      if (action.name === "Deactivate") {
        if (action.fields.length) {
          const { fieldName } = action.fields[0];
          return data[camelize(fieldName)] === "Active";
        }
      }

      if (action.name === "Activate") {
        if (action.fields.length) {
          const { fieldName } = action.fields[0];
          return data[camelize(fieldName)] === "Inactive";
        }
      }

      // Contract Actions

      if (action.name === "Discontinue") {
        return (
          (data.continuityMode === "Continued" || data.continuityMode === "") &&
          data.status === "Active"
        );
      }

      if (action.name === "Cancel Discontinuation") {
        return data.continuityMode === "Discontinued" && data.status === "Active";
      }

      if (action.name === "Activate Contract") {
        return data.status === "RenewalPending";
      }

      if (
        action.name === "Renew Contract" ||
        action.name === "Review Renewal Contract" ||
        action.name === "Print Renewal Notice"
      ) {
        const renewContractStatus = ["Active", "RenewalPending", "Ended"];
        return (
          (data.continuityMode === "Continued" || data.continuityMode === "") &&
          renewContractStatus.includes(data.status)
        );
      }

      if (action.name === "Send Notice") {
        return data.status === "Active";
      }

      if (action.name === "Terminate") {
        return (data.status === "Active") || (data.status === "Expired") || (data.status === "Ended") || (data.status === "RenewalPending");
      }

      if (action.name === "Print Tenancy Contract") {
        return data.status !== "Open";
      }

      if (action.name === "Print Lease Agreement") {
        return data.contractType === "Commercial" && data.status !== "Open";
      }

      if (action.name === "Transfer Contract") {
        return data.status === "Active";
      }

      if (
        action.name === "Print Move-Out Checklist" ||
        action.name === "Print Move-In Checklist"
      ) {
        return data.status !== "Open";
      }

      // Payment Receipt Actions

      if (
        objectName === dynamicObjectMap.get("PaymentReceiptObjectName") &&
        action.name === "Cancel Payment Receipt"
      ) {
        return data.status !== "Cancelled" && (userRoles.includes("admin") || userRoles.includes("accountant"));
      }

      // Revenue Recognition Actions

      if (objectName === dynamicObjectMap.get("RevenueRecognitionObjectName")) {
        if (action.name === "Cancel" && data.status !== "Cancelled") {
          return true;
        }

        return false;
      }

      // Service Request Actions

      if (action.name === "Work Order") {
        if (data?.status === "Rejected") {
          return false;
        }
      }

      if (action.name === "Assign Supervisor") {
        if (data?.status === "Rejected") {
          return false;
        }

        return !data?.supervisor?.id;
      }

      if (action.name === "Reassign Supervisor") {
        if (data?.status === "Rejected") {
          return false;
        }

        return data?.supervisor?.id;
      }

      if (action.name === "Escalate to Supervisor") {
        if (data?.status === "Rejected") {
          return false;
        }

        return data?.status === "Escalate" && data?.escalated;
      }

      // Tenant Actions

      if (action.name === "UploadDocument") {
        return false;
      }

      // Reservation Actions

      if (objectName === dynamicObjectMap.get("ReservationObjectName")) {
        if (action.actionType === "Api" && data.status === "Paid") {
          return true;
        }

        if (action.name === "Create Tenant" && data.status === "Paid" && !data.tenantCreated) {
          return true;
        }

        if (action.name === "Cancel" && data.status === "Paid") {
          return true;
        }

        return false;
      }

      if (
        action.objectName === dynamicObjectMap.get("ReservationObjectName") &&
        action?.name === "Cancel" &&
        ["Adjusted", "Cancelled", "ApprovedForRefund", "Refunded"].includes(data.status)
      ) {
        return false;
      }

      // Incident Report Actions

      if (
        objectName === dynamicObjectMap.get("IncidentReportObjectName") &&
        action.objectName === dynamicObjectMap.get("WorkOrderObjectName")
      ) {
        if (data.type === "Chargeable") {
          return true;
        }
        return false;
      }

      // Unit Actions

      if (action.name === "Merge Unit") {
        return !["Occupied", "Reserved"].includes(data?.status);
      }

      if (action.name === "Print BarCode") {
        return data?.barCode;
      }

      // General Invoice Actions

      if (objectName === dynamicObjectMap.get("GeneralInvoiceObjectName") && action.name === "Create General Receipt") {
        return Boolean(data.openBalance) && data.status !== "Cancelled";
      }

      if (objectName === dynamicObjectMap.get("GeneralReceiptObjectName") && action.name === "Cancel General Receipt") {
        return data.status !== "Cancelled";
      }

      if (objectName === dynamicObjectMap.get("GeneralInvoiceObjectName") && action.name === "Cancel General Invoice") {
        return !data.generalReceipt && data.status !== "Cancelled";
      }
      // Item Actions

      if (action.name === "Revalue Asset") {
        return data.itemType === "Asset" && data.status === "Active";
      }

      // Amc Invoice Actions

      if (objectName === dynamicObjectMap.get("AmcInvoiceObjectName")) {
        if (action.name === "Cancel" && data.status === "Approved") {
          return true;
        }

        return false;
      }

      // Expense Actions

      if (objectName === dynamicObjectMap.get("ExpenseObjectName")) {
        if (action.name === "Cancel" && data.status === "Approved") {
          return true;
        }

        if (action.name === "Reject" && data.status === "InProgress") {
          return true;
        }

        return false;
      }

      // Payment Actions

      if (objectName === dynamicObjectMap.get("PaymentObjectName")) {
        if (action.name === "Cancel") {
          return !["Rejected", "Cancelled"].includes(data.status);
        }
      }

      // PDC Actions

      if (objectName.toLowerCase() === dynamicObjectMap.get("PDCObjectName").toLowerCase()) {
        if (action.name === "Update Cleared Cheque") {
          return data.status === "Cleared";
        }

        if (action.name === "Cancel Bounced Cheque") {
          return data.status === "Bounced";
        }

        if (action.name === "Cancel Cleared Cheque") {
          return data.status === "Cleared";
        }

        return false;
      }

      // Petty Cash Actions

      if (objectName === dynamicObjectMap.get("PettyCashObjectName")) {
        if (action.name === "Revoke Technician Balance") {
          if (data.amount === 0) {
            return false;
          }

          if (data.technician?.designation === "Supervisor") {
            return false;
          }
        }
      }

      return action;
    });

    let isEditActionAllowed = true;

    if (
      objectName === dynamicObjectMap.get("ReservationObjectName") &&
      ["Approved", "Paid", "Cancelled"].includes(data.status)
    ) {
      isEditActionAllowed = userRoles.some((role) => ["leasing_executive", "admin"].includes(role));
    }

    if (
      objectName === dynamicObjectMap.get("ServiceRequestObjectName") &&
      data.status === "Closed"
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("PaymentObjectName") &&
      ["Posted", "Approved", "Cancelled"].includes(data.status)
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("ExpenseObjectName") &&
      ["Approved", "Rejected", "Cancelled", "Paid"].includes(data.status)
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("SupplierCreditNoteObjectName") &&
      ["Approved", "Rejected"].includes(data.status)
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("PettyCashClaimObjectName") &&
      ["Claimed", "Rejected"].includes(data.status)
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("AmcInvoiceObjectName") &&
      ["Paid", "Approved", "Cancelled"].includes(data.status)
    ) {
      isEditActionAllowed = false;
    }

    if (
      objectName === dynamicObjectMap.get("ContractObjectName") &&
      (data.status === "Draft" || data.status === "Open")
    ) {
      if (userRoles.some((role) => ["sales_team", "leasing_executive"].includes(role))) {
        filteredActions.push({
          actionType: "Edit",
        });
      }
    }

    if (!checkPermissionOverride(userRoles, pascalize(objectName), "edit")) {
      isEditActionAllowed = false;
    }

    if (objectName === dynamicObjectMap.get("MoveOutRequestObjectName")) {
      filteredActions.unshift({
        objectName,
        icon: "maintenance-icon",
        actionType: "Update",
        name: "Start Workflow",
        fields: []
      });
    }

    if (
      checkDynamicObjectPermission({
        permissions,
        scope: pascalize(objectName),
        action: "Update",
      }) &&
      isEditActionAllowed
    ) {
      filteredActions.unshift({
        actionType: "Edit",
      });
    }

    if (showTableActions) {
      if (
        checkDynamicObjectPermission({
          permissions,
          scope: pascalize(objectName),
          action: "View",
        })
      ) {
        filteredActions.unshift({
          actionType: "View",
        });
      }
    }

    filteredActions = filteredActions.filter((item) => !removeActions?.includes(item.actionType));
    return filteredActions.map((action) => {
      switch (action.actionType) {
        case "Create": {
          if (
            objectName === dynamicObjectMap.get("VisualInspectionObjectName") &&
            action.objectName === dynamicObjectMap.get("WorkOrderObjectName")
          ) {
            return {
              title: action.name,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                  createWorkOrder: true,
                  type:
                    data?.jobFor?.objectName === dynamicObjectMap.get("BuildingObjectName") ?
                      "scheduled" :
                      "reactive",
                  submitButtonText: "Create Work Order",
                });
                navigate(`/facility-management/work-order/new?${params}`);
              },
            };
          }
          if (
            action.objectName === dynamicObjectMap.get("UnitObjectName") &&
            action.name === "View Matching Units"
          ) {
            return {
              title: action.name,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                });

                navigate(`/sales/unit-matching?${params}`);
              },
            };
          }

          if (action.objectName === dynamicObjectMap.get("TenantObjectName")) {
            return {
              title: action.name,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                });

                navigate(`/leasing/tenant/new?${params}`);
              },
            };
          }

          if (action.objectName === dynamicObjectMap.get("ReservationObjectName")) {
            return {
              title: action.name,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                });

                navigate(`/leasing/reservation/new?${params}`);
              },
            };
          }

          if (
            objectName === dynamicObjectMap.get("IncidentReportObjectName") &&
            action.objectName === dynamicObjectMap.get("WorkOrderObjectName")
          ) {
            return {
              title: `${action.name}`,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                  createWorkOrder: true,
                  type:
                    data?.jobFor?.objectName === dynamicObjectMap.get("BuildingObjectName") ?
                      "scheduled" :
                      "reactive",
                  submitButtonText: "Create Work Order",
                });
                navigate(`/facility-management/work-order/new?${params}`);
              },
            };
          }

          if (
            objectName === dynamicObjectMap.get("ContractObjectName") &&
            action.name === "Renew Contract"
          ) {

            return {
              title: action.name,
              icon: action.icon,
              onClick: () => {
                const params = qs.stringify({
                  [camelize(objectName)]: id,
                  [camelize(action.name)]: true,
                  submitButtonText: action.name,
                });
                const url = `/${kebabCase(action.applicationName)}/${kebabCase(
                  action.objectName
                )}/new?${params}`;
                handleRenewContract(url);
              },
            };
          }

          return {
            title: action.name,
            icon: action.icon,
            onClick: () => {
              const params = qs.stringify({
                [camelize(objectName)]: id,
                [camelize(action.name)]: true,
                submitButtonText: action.name,
              });
              navigate(
                `/${kebabCase(action.applicationName)}/${kebabCase(
                  action.objectName
                )}/new?${params}`
              );
            },
          };
        }

        case "Update": {
          return {
            title: action.name,
            icon: action.icon,
            onClick: () => {
              setActiveAction(action);
              openActionModal();
            },
          };
        }

        case "CreatePerformaInvoice": {
          return {
            title: action.name,
            icon: action.icon,
            onClick: () => {
              navigate(`/facility-management/proforma-invoice/new?workOrder=${id}`);
            },
          };
        }

        case "View": {
          return {
            title: "View",
            icon: "eye",
            onClick: handleView,
          };
        }

        case "Edit": {
          return {
            title: "Edit",
            icon: "edit-icon",
            onClick: handleEdit,
          };
        }

        case "Delete": {
          return {
            title: "Delete",
            icon: "trash-icon",
            onClick: handleDeleteAction,
          };
        }

        case "Api": {
          if (action.name === "Print BarCode") {
            return {
              title: action.name,
              icon: action.icon,
              onClick: () => handlePrintBarCode(action, data),
            };
          }

          return {
            title: action.name,
            icon: action.icon,
            onClick: () => handlePrint(action),
          };
        }

        default:
          return {
            title: action.name,
            icon: action.icon,
            onClick: () => {
              setActiveAction(action);
              openActionModal();
            },
          };
      }
    });
  };

  const preparedActions = prepareActions();
  const handleCloseActionModal = () => {
    closeActionModal();
    setActiveAction(null);
  };

  if (!prepareActions().length) {
    return null;
  }

  return (
    <>
      <DynamicObjectActionModal
        id={id}
        isOpen={isActionModalOpen}
        onClose={handleCloseActionModal}
        action={activeAction}
        objectName={objectName}
      />
      <AlertModal onClose={closeAlertModal} isOpen={isAlertModalOpen} onConfirm={handleDelete} />
      <Dropdown.Root data-testid={getTestId(testId)}>
        <Dropdown.Trigger asChild data-testid={getTestId(`${testId}-Button`)}>
          {trigger}
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            className="dropdown-menu-content action-dropdown-menu"
            side="bottom"
            align="end"
          >
            {preparedActions.map((action, index) => (
              <Dropdown.Item
                key={`action-${index}`}
                className={`dropdown-menu-item action-menu-item ${
                  action.title === "Delete" ? "action-menu-delete-item" : ""
                }`}
                onSelect={() => action.onClick()}
                data-testid={getTestId(`${testId}-${pascalize(action.title)}-Button`)}
              >
                <SvgIcon icon={action.icon} />
                <a>{action.title}</a>
              </Dropdown.Item>
            ))}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    </>
  );
}

ActionDropdown.propTypes = {
  objectName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  trigger: PropTypes.node.isRequired,
  showTableActions: PropTypes.bool,
  testId: PropTypes.string,
  removeActions: PropTypes.array,
};

ActionDropdown.defaultProps = {
  showTableActions: false,
  testId: "",
  removeActions: [],
};

export default ActionDropdown;

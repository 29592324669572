import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getContractSecurityDepositColumns() {

  const colArr = [
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number",
      value: true,
      columnId: "unitNumber",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Name",
      value: true,
      columnId: "unitName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Number",
      value: true,
      columnId: "customerNumber",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Date",
      value: true,
      columnId: "date",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => new Date(value).toLocaleDateString()
    },
    {
      label: "Receipt Number",
      value: true,
      columnId: "receiptNumber",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Deposit",
      value: true,
      columnId: "deposit",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Charge By Tenant",
      value: true,
      columnId: "chargeByTenant",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Charge By Occupant",
      value: true,
      columnId: "chargeByOccupant",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "End Date",
      value: true,
      columnId: "endDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => new Date(value).toLocaleDateString()
    }
  ];

  return colArr;
}

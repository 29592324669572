import {
  useReducer, useRef, useEffect, useState
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { pascalize, pascalizeKeys } from "humps";
import { useQuery } from "@tanstack/react-query";
import { Button, Loader } from "@hydra/atom/components";
import { isNull, kebabCase } from "lodash";
import { BoxedContent, Header, RequirePermission, HeaderLeftContent } from "@/components/common";
import { ExtendTimeModal } from "@/components/modals";
import {
  workOrderFormReducer,
  initialState,
  setFormValue,
  scheduleJob,
  editJob,
  resetState,
  setInitialState,
  setInitialStateForServiceRequest,
  setInitialStateForVisualInspection,
  selectCurrentJobState,
  setJobData,
  setType,
} from "@/reducers/facility/workOrderFormReducer";
import { AddWorkOrderStep, AddWorkOrderInspectionStep } from "@/components/facility/planboard";
import { formatWorkOrder, formatWorkOrderJob } from "@/utils/facility/helpers";
import {
  createDynamicObjectRecord,
  bulkCreateDynamicObjectRecord,
  updateDynamicObjectRecord,
  getDynamicObjectRecordById,
  getDynamicObjectRecords,
} from "@/api/dynamic/dynamicObjectNameApi";
import { createTask } from "@/api/workflow/workflowApi";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import workflowMap from "@/utils/maps/workflowMap";
import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import showToast from "@/utils/toast/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { formatApiPayloadDate } from "@/utils/helpers";
import { getCompany } from "@/api/user/authApi";

const prepareWorkOrderData = (workOrder) => {
  const companyId = getCompany();

  const workOrderData = {
    name: workOrder.title,
    description: workOrder.description,
    type: pascalize(workOrder.type),
    incidentType: "",
    serviceRequest: "",
    supervisor: workOrder?.supervisor?.value,
    category: workOrder?.category?.value,
    subCategory: workOrder?.subCategory?.value || "",
    unit: null,
    building: null,
    priority: "",
    status: pascalize(workOrder.workOrderStatus.value),
    visualInspection: workOrder?.visualInspection?.value,
    incidentReport: workOrder?.incidentReport?.value,
    photos: [],
    company: companyId,
  };

  if (workOrder.type.toLowerCase() === "reactive") {
    workOrderData.incidentType = pascalize(workOrder.incidentType);

    workOrderData.unit = workOrder?.unit?.value;
    workOrderData.building = workOrder?.building?.value;
    workOrderData.priority =
      workOrder.serviceRequest?.priority?.value || workOrder?.priority?.value;
    if (workOrder.incidentType.toLowerCase() === "servicerequest") {
      workOrderData.serviceRequest = workOrder.serviceRequest.value;
    }
  }

  if (workOrder.type.toLowerCase() === "scheduled") {
    workOrderData.priority = "Low";
    workOrderData.blanketAgreement = workOrder?.blanketAgreement?.value || "";
    workOrderData.building = workOrder?.building?.value;
  }

  return workOrderData;
};

const prepareJobData = (job, workOrder) => {
  const companyId = getCompany();
  const workOrderType = workOrder.type.toLowerCase();
  const jobData = {
    company: companyId,
    workOrder: workOrder.id,
    jobType: "",
    category: workOrder?.category?.value,
    subCategory: workOrder?.subCategory?.value,
    serviceRequest: workOrder?.serviceRequest?.value,
    name: job.title || job.jobTitle || "",
    number: job?.number || workOrder.number,
    description: job.description || job.jobDescription || "",
    tenant: [],
    unit: workOrderType === "reactive" ? workOrder?.unit?.value : null,
    building: workOrderType === "reactive" ? workOrder?.building?.value : null,
    blanketAgreement: workOrder.blanketAgreement?.value,
    startDate: job.dateRange[0] ? formatApiPayloadDate(new Date(job?.dateRange[0])) : null,
    externalSupervisor: job?.externalSupervisor?.value,
    externalTeam: job?.externalTeam?.value,
    externalNote: job?.externalNote,
    endDate: job.dateRange[1] ? formatApiPayloadDate(new Date(job?.dateRange[1])) : null,
    startTime: job.timeRange[0]?.length < 6 ? `${job?.timeRange[0]}:00` : job?.timeRange[0],
    endTime: job.timeRange[1]?.length < 6 ? `${job?.timeRange[1]}:00` : job?.timeRange[1],
    technicianSkill: job.technician.map((t) => t.value),
    team: [],
    jobStatus: job.status.value,
    priority: workOrder.priority ? workOrder.priority.value : "Low",
    materials: "",
    equipment: job.equipment.map((e) => e.value),
    notes: "",
    photos: job.attachments || [],
    videos: job.videos || [],
    audios: job.audios || [],
    isVendor: workOrder.isVendor || job.isVendor,
    supervisor: workOrder?.supervisor?.value,
  };

  if (workOrderType === "reactive") {
    jobData.jobType = job.jobType;

    if (workOrder.incidentType.toLowerCase() === "servicerequest") {
      jobData.tenant = workOrder.tenant?.value ? workOrder.tenant.value : null;
    }
  }

  if (workOrderType === "scheduled") {
    if (job?.jobFor?.value || workOrder?.jobFor?.value) {
      jobData.jobFor = {
        key: job?.jobFor?.value ? job.jobFor.value : workOrder.jobFor.value,
        objectName: job?.jobFor?.lookupObjectName ?
          job.jobFor.lookupObjectName :
          workOrder.jobFor.lookupObjectName,
      };
    }
    jobData.jobType = "Job";
    jobData.priority = "Low";
  }

  return jobData;
};

const prepareMaterialData = (material) => {
  const companyId = getCompany();

  return {
    company: companyId,
    item: material.item.value,
    quantity: material.quantity,
    isIssued: false,
    quantityUsed: 0,
    price: material.price,
  };
};

const createWorkOrder = async (workOrder, workflowId) => {
  const workOrderData = prepareWorkOrderData(workOrder);
  if (workflowId) {
    workOrderData.workflowId = workflowId;
  }

  // Save work order
  const workOrderResponse = await createDynamicObjectRecord(
    dynamicObjectMap.get("WorkOrderObjectName"),
    pascalizeKeys(workOrderData)
  );

  const jobData = workOrder.jobs.map((job) => ({
    ...prepareJobData(job, workOrder),
    workOrder: workOrderResponse.id,
  }));

  // Save jobs using bulkInsert

  const jobIds = await bulkCreateDynamicObjectRecord(
    dynamicObjectMap.get("WorkOrderJobObjectName"),
    pascalizeKeys(jobData)
  );

  const materialsData = [];
  workOrder.jobs.forEach((job, index) => {
    job.materials.forEach((material) => {
      materialsData.push({
        [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: jobIds.data[index].id,
        ...prepareMaterialData(material),
      });
    });
  });

  // Save materials using bulkInsert

  await bulkCreateDynamicObjectRecord(
    dynamicObjectMap.get("JobMaterialObjectName"),
    pascalizeKeys(materialsData)
  );

  const externalInspectionIndex = workOrder.jobs.findIndex((job) => job.isVendor);

  if (externalInspectionIndex !== -1) {
    createTask({
      objectName: dynamicObjectMap.get("WorkOrderJobObjectName"),
      workflowStep: "Propose Inspection Time",
      objectEventType: "Updating",
      workflowName: workflowMap.get("VendorFlow"),
      recordId: jobIds.data[externalInspectionIndex].id,
    });
  }

  return workOrderResponse;
};

const updateWorkOrder = async (workOrder) => {
  const workOrderData = prepareWorkOrderData(workOrder);

  const response = await updateDynamicObjectRecord(
    dynamicObjectMap.get("WorkOrderObjectName"),
    workOrder.id,
    pascalizeKeys(workOrderData)
  );

  return response;
};

export default function WorkOrderForm() {
  const [state, dispatch] = useReducer(workOrderFormReducer, initialState);
  const workOrderFormSubmitButtonRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const jobFormSubmitButtonRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const { id: workOrderId } = useParams();
  const { redirect } = useTaskRedirect();

  const jobIdParam = searchParams.get("jobId");
  const serviceRequestParam = searchParams.get("serviceRequest");
  const visualInspectionParam = searchParams.get("visualInspection");
  const typeParam = searchParams.get("type");
  const taskIdParam = searchParams.get("taskId");
  const workflowIdParam = searchParams.get("workflowId");
  const isEditing = Boolean(workOrderId);

  const { data: workOrderRes, isInitialLoading: isLoadingWorkOrder } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderObjectName")), workOrderId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("WorkOrderObjectName"), workOrderId),
    {
      enabled: workOrderId !== undefined,
    }
  );

  const { data: jobsRes, isInitialLoading: isLoadingJobs } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")), workOrderId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        workOrder: workOrderId,
        queryMode: "Deep",
        sortBy: "CreatedAt",
        sortType: "DESC",
      }),
    {
      enabled: workOrderId !== undefined,
    }
  );

  useEffect(() => {
    if (workOrderRes && jobsRes) {
      const workOrder = formatWorkOrder(workOrderRes);
      const workOrderJobs = jobsRes?.data.map((j) => formatWorkOrderJob(j));
      workOrder.jobs = workOrderJobs;
      dispatch(setInitialState(workOrder));

      if (jobIdParam) {
        dispatch(setJobData(jobIdParam));
      }
    }
  }, [workOrderRes, jobsRes, jobIdParam]);

  useEffect(() => {
    if (serviceRequestParam) {
      dispatch(setInitialStateForServiceRequest());
    }

    if (visualInspectionParam) {
      dispatch(setInitialStateForVisualInspection());
    }

    if (typeParam) {
      dispatch(setType(typeParam));
    }
  }, [searchParams.size]);

  const submitJobForm = () => {
    jobFormSubmitButtonRef.current.click();
  };

  const submitWorkOrderForm = () => {
    workOrderFormSubmitButtonRef.current.click();
  };

  useEffect(() => {
    if (
      (state.jobType === "Inspection" && !state.isEditingJob) ||
      (state.jobType === "Job" && state.isVendor)
    ) {
      dispatch(
        setFormValue("status", {
          label: "Proposed",
          value: "Proposed",
          default: true,
          color: statusColorMap.get("proposed"),
        })
      );
    } else if (state.jobType === "Job" && !state.isEditingJob) {
      dispatch(
        setFormValue("status", {
          label: "Scheduled",
          value: "Scheduled",
          default: true,
          color: statusColorMap.get("scheduled"),
        })
      );
    }
  }, [state.jobType, state.isVendor]);

  const handleSaveJob = async () => {
    const jobState = selectCurrentJobState(state);
    const jobData = prepareJobData(jobState, state);

    if (workflowIdParam && taskIdParam) {
      jobData.workflowId = workflowIdParam;
      jobData.taskId = taskIdParam;
    }

    setIsLoading(true);
    if (state.isEditing) {
      if (state.selectedJobId) {
        await updateDynamicObjectRecord(
          dynamicObjectMap.get("WorkOrderJobObjectName"),
          state.selectedJobId,
          {
            [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: state.id,
            ...pascalizeKeys(jobData),
          }
        );

        if (jobData.isVendor && taskIdParam) {
          redirect("", {
            recordId: state.selectedJobId,
            success: true,
          });
        }
      } else {
        const response = await createDynamicObjectRecord(
          dynamicObjectMap.get("WorkOrderJobObjectName"),
          pascalizeKeys(jobData)
        );

        const newJobId = response.id;

        const materialsData = jobState.materials.map((material) => ({
          [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: newJobId,
          ...prepareMaterialData(material),
        }));

        // Save materials using bulkInsert

        const materialKeys = await bulkCreateDynamicObjectRecord(
          dynamicObjectMap.get("JobMaterialObjectName"),
          pascalizeKeys(materialsData)
        );

        const isVendorInspection = jobData.isVendor;

        if (isVendorInspection) {
          createTask({
            objectName: dynamicObjectMap.get("WorkOrderJobObjectName"),
            workflowStep: "Propose Inspection Time",
            objectEventType: "Updating",
            workflowName: workflowMap.get("VendorFlow"),
            recordId: newJobId,
          });
        }

        dispatch(
          scheduleJob({
            id: newJobId,
            material: jobState.materials.map((m, index) => ({
              ...m,
              key: materialKeys[index],
              [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: newJobId,
            })),
          })
        );
        setIsLoading(false);
        return;
      }
    }

    if (state.isEditingJob && !isNull(state.selectedJobId)) {
      dispatch(editJob());
    } else {
      dispatch(scheduleJob());
    }

    setIsLoading(false);
  };

  const handlePrevious = () => {
    dispatch(setFormValue("technician", []));
    dispatch(setFormValue("activeStep", 2));
  };

  const handleCancel = (workOrderKey) => {
    dispatch(resetState());
    redirect("/facility-management/work-orders", {
      recordId: workOrderKey,
      success: true,
    });
  };

  const handleSaveWorkOrder = async () => {
    setIsLoading(true);
    if (!state.isEditing) {
      try {
        const response = await createWorkOrder(state, workflowIdParam);
        showToast("Work Order Created", "success");
        handleCancel(response.id);
      } catch (e) {
        showToast("Could not save work order. Please try again!", "error");
      }
    } else {
      try {
        await updateWorkOrder(state);
        showToast("Work Order Updated", "success");
        handleCancel();
      } catch (e) {
        showToast("Could not update work order. Please try again!", "error");
      }
    }

    setIsLoading(false);
  };

  const getMaintenanceHeader = () => {
    if (state.activeStep === 3) {
      return "Schedule Job";
    }

    if (state.type === "reactive") {
      return "Corrective Maintenance";
    }

    if (state.type === "scheduled") {
      return "Preventive Plan Maintenance";
    }

    return <Loader />;
  };

  const onExtendTime = (details) => {
    dispatch(setFormValue("dateRange", [state.dateRange?.[0], details.endDate]));
    dispatch(setFormValue("timeRange", details.timeRange));
    dispatch(setFormValue("technician", [...state.technician, ...details.technician]));
  };

  if (isLoadingWorkOrder || isLoadingJobs) return <Loader />;
  return (
    <div className="inspection-container">
      {state.activeStep !== 4 && state.activeStep !== 3 ? (
        <BoxedContent>
          <Header
            showBreadcrumb
            leftContent={(
              <HeaderLeftContent
                title={getMaintenanceHeader()}
                icon={state.activeStep === 3 ? "calendar-icon" : ""}
              />
            )}
          />
          {state.activeStep === 2 && (
            <AddWorkOrderStep
              state={state}
              dispatch={dispatch}
              ref={workOrderFormSubmitButtonRef}
              onSubmit={handleSaveWorkOrder}
            />
          )}

          {state.activeStep !== 1 && (
            <>
              {state.activeStep === 2 && (
                <div className="row">
                  <div className="col">
                    <div className="buttons-at-end">
                      <Button bordered small onClick={handleCancel}>
                        Cancel
                      </Button>
                      <RequirePermission
                        parent="Model"
                        scope={dynamicObjectMap.get("WorkOrderObjectName")}
                        action={isEditing ? "Update" : "Insert"}
                      >
                        <Button
                          small
                          className="save-btn"
                          onClick={submitWorkOrderForm}
                          loading={isLoading}
                        >
                          Save Work Order
                        </Button>
                      </RequirePermission>
                    </div>
                  </div>
                </div>
              )}
              {(state.activeStep === 3 || state.activeStep === 4) && (
                <div className="row">
                  {state.activeStep === 3 && (
                    <div className="col">
                      <div className="buttons-at-end">
                        <Button bordered small onClick={handlePrevious}>
                          Previous
                        </Button>
                        <RequirePermission
                          parent="Model"
                          scope={dynamicObjectMap.get("WorkOrderJobObjectName")}
                          action={isEditing ? "Update" : "Insert"}
                        >
                          {isEditing && (
                            <Button bordered small onClick={() => setOpenModal(true)}>
                              Extend Time
                            </Button>
                          )}
                          <Button
                            small
                            className="save-btn"
                            loading={isLoading}
                            onClick={submitJobForm}
                          >
                            Save Job
                          </Button>
                        </RequirePermission>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </BoxedContent>
      ) : (
        <AddWorkOrderInspectionStep
          state={state}
          dispatch={dispatch}
          onSubmit={handleSaveJob}
          handlePrevious={handlePrevious}
          isEditing={isEditing}
          loading={isLoading}
        />
      )}
      {isEditing && (
        <ExtendTimeModal
          isOpen={openModal}
          state={state}
          setOpenModal={setOpenModal}
          onSubmit={(details) => onExtendTime(details)}
        />
      )}
    </div>
  );
}

import { uniqueId } from "lodash";
import { actionCreator } from "@/utils/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_STATE = "SET_STATE";
export const SET_SERVICE_DATA = "SET_SERVICE_DATA";
export const SET_VISUAL_INSPECTION_DATA = "SET_VISUAL_INSPECTION_DATA";
export const SET_INCIDENT_REPORT_DATA = "SET_INCIDENT_REPORT_DATA";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_TYPE = "SET_TYPE";
export const RESET_STATE = "RESET_STATE";
export const SCHEDULE_JOB = "SCHEDULE_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const REMOVE_JOB = "REMOVE_JOB";
export const SET_READ_ONLY_JOB_STATE = "SET_READ_ONLY_JOB_STATE";
export const SET_INITIAL_STATE_FOR_SERVICE_REQUEST = "SET_INITIAL_STATE_FOR_SERVICE_REQUEST";
export const SET_INITIAL_STATE_FOR_VISUAL_INSPECTION = "SET_INITIAL_STATE_FOR_VISUAL_INSPECTION";
export const SCHEDULE_INSPECTION_INITIAL_STATE = "SCHEDULE_INSPECTION_INITIAL_STATE";
export const SCHEDULE_JOB_INITIAL_STATE = "SCHEDULE_JOB_INITIAL_STATE";

const initialJobState = {
  jobType: "",
  jobTitle: "",
  number: "",
  jobDescription: "",
  workOrder: null,
  technician: [],
  externalSupervisor: null,
  isVendor: false,
  proposedTime: [],
  jobFor: null,
  externalTeam: null,
  status: {
    label: "Proposed",
    value: "Proposed",
    default: true,
    color: statusColorMap.get("proposed"),
  },
  KPIstatus: { label: "30 minutes", value: "30 minutes", default: true },
  materials: [],
  dateRange: [null, null],
  timeRange: [null, null],
  equipment: [],
  asset: [],
  jobUnit: null,
  externalNote: "",
  notes: [],
  internal: true,
  attachments: [],
};

export const initialState = {
  activeStep: 1,
  isEditing: false,
  isEditingJob: false,
  isReadOnly: false,
  type: "reactive",
  title: "",
  description: "",
  unit: null,
  serviceRequest: null,
  blanketAgreement: null,
  supervisor: null,
  tenant: null,
  selectedJobId: null,
  incidentType: "serviceRequest",
  subCategory: null,
  category: null,
  workOrderStatus: {
    label: "Pending",
    value: "Pending",
    default: true,
    color: statusColorMap.get("pending"),
  },
  priority: { label: "Medium", value: "Medium", color: statusColorMap.get("medium") },
  ...initialJobState,
  jobs: [],
};

export const workOrderFormReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case SET_STATE:
      return {
        ...state,
        ...action.payload
      };

    case SET_TYPE:
      return {
        ...state,
        activeStep: 2,
        type: action.payload.type,
      };

    case SET_INITIAL_STATE:
      return {
        ...state,
        activeStep: 2,
        isEditing: true,
        ...action.payload,
      };

    case SCHEDULE_INSPECTION_INITIAL_STATE:
      return {
        ...state,
        activeStep: 4,
        isEditingJob: false,
        jobType: "Inspection",
        jobTitle: `Inspection for ${state.title}`,
        number: state.number,
        jobDescription: state.description,
      };

    case SCHEDULE_JOB_INITIAL_STATE:
      return {
        ...state,
        materials: [],
        equipment: [],
        activeStep: 3,
        jobType: "Job",
        jobTitle: `Job for ${state.title}`,
        number: state.number,
        jobDescription: state.description,
      };

    case SET_INITIAL_STATE_FOR_SERVICE_REQUEST:
      return {
        ...state,
        type: "reactive",
        activeStep: 2,
      };

    case SET_INITIAL_STATE_FOR_VISUAL_INSPECTION:
      return {
        ...state,
        incidentType: "standAlone",
        type: "reactive",
        activeStep: 2,
      };

    case SET_SERVICE_DATA: {
      if (!action.payload) {
        return {
          ...state,
          title: "",
          unit: null,
          subCategory: null,
          tenant: null,
          label: null,
          description: "",
          category: null,
          supervisor: null,
          serviceRequest: null,
        };
      }

      const {
        unit, subCategory, tenant, label, description, category, supervisor, building
      } =
        action.payload;

      return {
        ...state,
        title: `WorkOrder for ${label}`,
        description,
        subCategory,
        category,
        unit,
        tenant,
        supervisor,
        serviceRequest: action.payload,
        building
      };
    }

    case SET_VISUAL_INSPECTION_DATA: {
      const {
        title, description, unit, jobFor, building, visualInspection
      } = action.payload;

      return {
        ...state,
        title,
        description,
        unit,
        jobFor,
        building,
        visualInspection
      };
    }

    case SET_INCIDENT_REPORT_DATA: {
      const {
        title, description, unit, jobFor, building, incidentReport
      } = action.payload;

      return {
        ...state,
        title,
        description,
        unit,
        jobFor,
        building,
        incidentReport
      };
    }

    case RESET_STATE:
      return {
        ...initialState,
      };

    case SCHEDULE_JOB: {
      const {
        jobTitle,
        jobDescription,
        technician,
        status,
        materials,
        dateRange,
        timeRange,
        equipment,
        notes,
        jobUnit,
        jobFor,
        jobType,
        isVendor,
        attachments,
        externalSupervisor,
        proposedTime,
        externalNote,
        number,
        externalTeam,
      } = state;

      const job = {
        id: uniqueId(),
        title: jobTitle,
        description: jobDescription,
        technician,
        status,
        number,
        isVendor,
        materials,
        dateRange,
        timeRange,
        jobFor,
        equipment,
        jobType,
        notes,
        attachments,
        externalSupervisor,
        proposedTime,
        externalTeam,
        externalNote,
      };

      if (action.payload) {
        job.id = action.payload.id;
        if (action?.payload?.materials) job.materials = [...action.payload.materials];
      }

      if (state.type === "scheduled") {
        job.jobUnit = jobUnit;
        job.jobFor = jobFor;
      }

      return {
        ...state,
        ...initialJobState,
        activeStep: 2,
        jobs: [...state.jobs, job],
      };
    }

    case SET_JOB_DATA: {
      const { id } = action.payload;
      const jobData = state.jobs.find((j) => j.id === id);

      return {
        ...state,
        isEditingJob: true,
        selectedJobId: id,
        activeStep: jobData.jobType === "Inspection" ? 4 : 3,
        jobTitle: jobData.title,
        jobDescription: jobData.description,
        number: jobData.number,
        technician: jobData.technician,
        externalSupervisor: jobData.externalSupervisor,
        proposedTime: jobData.proposedTime,
        isVendor: jobData.isVendor,
        externalTeam: jobData.externalTeam,
        externalNote: jobData.externalNote,
        status: jobData.status,
        materials: jobData.materials,
        dateRange: jobData.dateRange,
        timeRange: jobData.timeRange,
        notes: jobData.notes,
        equipment: jobData.equipment,
        jobUnit: jobData.jobUnit,
        jobFor: jobData.jobFor,
        jobType: jobData.jobType,
        attachments: jobData.attachments,
      };
    }

    case SET_READ_ONLY_JOB_STATE: {
      const jobState = state.jobs.find((j) => j.id === action.payload);

      return {
        ...state,
        isReadOnly: true,
        selectedJobId: action.payload,
        activeStep: 3,
        jobTitle: jobState.title,
        number: jobState.number,
        jobDescription: jobState.description,
        technician: jobState.technician,
        externalSupervisor: jobState.externalSupervisor,
        proposedTime: jobState.proposedTime,
        isVendor: jobState.isVendor,
        externalTeam: jobState.externalTeam,
        externalNote: jobState.externalNote,
        status: jobState.status,
        materials: jobState.materials,
        dateRange: jobState.dateRange.map((d) => new Date(d)),
        timeRange: jobState.timeRange,
        notes: jobState.notes,
        equipment: jobState.equipment,
        jobUnit: jobState.jobUnit,
        jobFor: jobState.jobFor,
        jobType: jobState.jobType,
        attachments: jobState.attachments,
      };
    }

    case EDIT_JOB: {
      const selectedJobIndex = state.jobs.findIndex((j) => j.id === state.selectedJobId);
      const updatedJobs = [...state.jobs];
      updatedJobs[selectedJobIndex] = {
        id: state.selectedJobId,
        title: state.jobTitle,
        number: state.number,
        description: state.jobDescription,
        technician: state.technician,
        externalSupervisor: state.externalSupervisor,
        proposedTime: state.proposedTime,
        isVendor: state.isVendor,
        externalTeam: state.externalTeam,
        externalNote: state.externalNote,
        status: state.status,
        materials: state.materials,
        dateRange: state.dateRange,
        timeRange: state.timeRange,
        equipment: state.equipment,
        notes: state.notes,
        jobType: state.jobType,
        attachments: state.attachments,
      };

      if (state.type === "scheduled") {
        updatedJobs[selectedJobIndex].jobUnit = state.jobUnit;
        updatedJobs[selectedJobIndex].jobFor = state.jobFor;
      }

      return {
        ...state,
        ...initialJobState,
        selectedJobId: null,
        isEditingJob: false,
        activeStep: 2,
        jobs: updatedJobs,
      };
    }

    case REMOVE_JOB: {
      const updatedJobData = state.jobs.filter((j) => j.id !== action.payload);

      return {
        ...state,
        jobs: updatedJobData,
      };
    }

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setState = (value) => actionCreator(SET_STATE, value);

export const setServiceRequestData = (value) => actionCreator(SET_SERVICE_DATA, value);

export const setVisualInspectionData = (value) => actionCreator(SET_VISUAL_INSPECTION_DATA, value);

export const setIncidentReportData = (value) => actionCreator(SET_INCIDENT_REPORT_DATA, value);

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);

// eslint-disable-next-line arrow-body-style
export const scheduleInspectionInitialState = (value) => {
  return actionCreator(SCHEDULE_INSPECTION_INITIAL_STATE, value);
};

export const scheduleJobInitialState = (value) => actionCreator(SCHEDULE_JOB_INITIAL_STATE, value);

// eslint-disable-next-line arrow-body-style
export const setInitialStateForServiceRequest = () => {
  return actionCreator(SET_INITIAL_STATE_FOR_SERVICE_REQUEST);
};

// eslint-disable-next-line arrow-body-style
export const setInitialStateForVisualInspection = () => {
  return actionCreator(SET_INITIAL_STATE_FOR_VISUAL_INSPECTION);
};

export const setType = (value) => actionCreator(SET_TYPE, { type: value });

export const resetState = () => actionCreator(RESET_STATE);

export const scheduleJob = (value = null) => actionCreator(SCHEDULE_JOB, value);

export const editJob = () => actionCreator(EDIT_JOB);

export const setJobData = (id) => actionCreator(SET_JOB_DATA, { id });

export const deleteJob = (id) => actionCreator(REMOVE_JOB, id);

export const setReadOnlyJobState = (id) => actionCreator(SET_READ_ONLY_JOB_STATE, id);

export const selectCurrentJobState = (state) => {
  const jobState = {};
  Object.keys(initialJobState).forEach((key) => {
    jobState[key] = state[key];
  });

  return jobState;
};

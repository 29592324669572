import { getContractSecurityDepositRows, getContractSecurityDepositColumns } from "./contract-security-deposit";
import { getContractSecurityDeposit } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const filters = {
  TenantNumber: "*",
  ContractNumber: "*",
  ComponentNumber: "*",
};

const additionalFilterOptions = [
  { key: "tenantNumber", apiKey: "TenantNumber", keyType: "number" },
  { key: "contractNumber", apiKey: "ContractNumber", keyType: "number" },
  { key: "componentNumber", apiKey: "ComponentNumber", keyType: "number" }
];

export default function ReportContractSecurityDeposit({ activeCompany }) {
  return (
    <DynamicReport
      reportName="contract-security-deposit"
      activeCompany={activeCompany}
      columnsDataProvider={getContractSecurityDepositColumns}
      rowsDataProvider={getContractSecurityDepositRows}
      fetchData={getContractSecurityDeposit}
      exportUrl="reports/export-query-report/contract-security-deposit"
      filtersInitialState={filters}
      filterOptions={additionalFilterOptions}
      // isPaginated={false}
      orderBy="ComponentNumber"
      showDateRangeFilter={false}
    />
  );
}

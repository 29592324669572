import { getColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getBounceChequeColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 14;

  const colArr = [
    {
      label: "Component Name", value: true, columnId: "componentName", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Number", value: true, columnId: "componentNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number", value: true, columnId: "contractNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Bank Name", value: true, columnId: "bankName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Description", value: true, columnId: "paymentReceiptDescription", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Bounce Reason", value: true, columnId: "bounceReason", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Status", value: true, columnId: "status", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Due Date", value: true, columnId: "dueDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Clearance Date", value: true, columnId: "clearanceDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Number", value: true, columnId: "number", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Number", value: true, columnId: "paymentReceiptNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Date", value: true, columnId: "paymentReceiptDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Unit Number", value: true, columnId: "unitNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status", value: true, columnId: "unitStatus", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "View", value: true, columnId: "views", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Floor", value: true, columnId: "floorName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Area (m^2)", value: true, columnId: "unitArea", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Bedroom", value: true, columnId: "bedrooms", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Number", value: true, columnId: "customerNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name", value: true, columnId: "tenantName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type", value: true, columnId: "tenantType", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "CHQ Number", value: true, columnId: "chequeNo", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "CHQ Date", value: true, columnId: "chequeDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "CHQ Amount", value: true, columnId: "amount", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "CHQ Receipt Number", value: true, columnId: "chequeNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Cheque Returned Bank", value: true, columnId: "chequeReturnedBank", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Customer Name", value: true, columnId: "customerName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Cheque Amount", value: true, columnId: "chequeAmount", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark", value: true, columnId: "unitMark", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Customer Cheque Bank ", value: true, columnId: "customerChequeBank ", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
  ];

  return colArr;
}
